import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '@prismicio/react';

import Layout from '@/components/Layout';
import components from '../slices';

const FeatureTemplate = ({ data, pageContext }) => {
  const { body: slices, social_body: socialBody } = data.prismicFeature.data;

  // html head data
  const headData = useMemo(
    () =>
      socialBody.map(({ primary: { description, image, title } }) => ({
        title: title.text,
        description: description.text,
        ogTitle: title.text,
        ogDescription: description.text,
        ogImageUrl: image.url,
      }))[0],
    [socialBody],
  );

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <SliceZone slices={slices} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query featureQuery($lang: String, $uid: String) {
    prismicFeature(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        social_body {
          ... on PrismicFeatureDataSocialBodyGeneralCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicFeatureDataSocialBodyTwitterCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...FeatureSplashSection
          ...FeatureContentSpot
          ...FeatureTabs
          ...FeatureInlineGrid
          ...FeatureNotificationBanner
          ...FeatureCenteredContentSpot
          ...FeatureSpotGrid
          ...FeatureFaqs
          ...FeatureSlides
          ...FeatureVideo
          ...FeatureRichText
          ...FeatureImgaeWithCaption
          ...FeatureBanner
          ...FeatureStats
          ...FeaturePlatforms
          ...FeaturePageForm
        }
      }
    }
  }
`;

export default withPrismicPreview(FeatureTemplate);
